// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v716demo1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'api',
  firebaseConfig : {
    apiKey: "AIzaSyD_cRxIW8WpAvihOD_0uefqfL-XBe5CcFg",
    authDomain: "prodvalbridge.firebaseapp.com",
    databaseURL: "https://prodvalbridge.firebaseio.com",
    projectId: "prodvalbridge",
    storageBucket: "prodvalbridge.appspot.com",
    messagingSenderId: "108271377208",
    appId: "1:108271377208:web:091d11d6e8f8e00365bfcd",
    measurementId: "G-FSQSKQZDH1"
  }
    
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
