import { Injectable, NgZone } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of, from } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private ngZone: NgZone,
    private authService: AuthService,
    private afAuth: AngularFireAuth,
    private firestore: AngularFirestore
  ) {}

  getUserCollection(): Observable<any[]> {
    return this.firestore.collection('user_valnow').valueChanges();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.afAuth.authState.pipe(
      switchMap(authState => {
        if (authState) {
          return this.getUserCollection().pipe(
            map(users => {
              const userExists = users.some((user: any) => user.email === authState.email);
              if (userExists) {
                return true;
              } else {
                this.ngZone.run(() => this.router.navigate(['/auth/login']));
                return false;
              }
            }),
            catchError(error => {
              console.error('Error in AuthGuard:', error);
              this.ngZone.run(() => this.router.navigate(['/auth/login']));
              return of(false);
            })
          );
        } else {
          this.ngZone.run(() => this.router.navigate(['/auth/login']));
          return of(false);
        }
      })
    );
  }
}
